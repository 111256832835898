import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@material-ui/core';
import { Clear, ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { useTheme } from '@material-ui/styles';

import { UnitSelector } from 'common/components';
import { capitalizeFirstLetter } from 'helpers/string';
import usePowerBIReportCreate from 'hooks/powerBIReport/mutations/usePowerBIReportCreate';
import { LANGUAGES } from 'i18n/utils';

import { REPORT_CATEGORIES, REPORT_DATASOURCES } from './utils';

function CreateReport({ units }) {
    const { i18n } = useTranslation();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync: createReport } = usePowerBIReportCreate();

    const [open, setOpen] = useState(false);

    const [reportData, setReportData] = useState({
        name: {
            'da-DK': '',
            'de-DE': '',
            'en-US': '',
            'es-ES': '',
            'fr-FR': '',
            'nb-NO': '',
            'sv-SE': ''
        },
        tags: [],
        dataSources: [],
        versions: {
            1: {
                powerBIWorkspaceId: '',
                powerBIReportId: ''
            }
        },
        permission: { type: 'global', excludedUnits: [] /* Used with global */, units: [] /* Used with selective */ }, // or 'selective'
        defaultCategory: REPORT_CATEGORIES.Brand,
        internalNote: '',
        description: {
            'da-DK': '',
            'de-DE': '',
            'en-US': '',
            'es-ES': '',
            'fr-FR': '',
            'nb-NO': '',
            'sv-SE': ''
        }
    });

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        if (!reportData.name['en-US']) {
            enqueueSnackbar('At least an English name is required', { variant: 'error' });

            return;
        }

        if (!reportData.versions[1]?.powerBIWorkspaceId) {
            enqueueSnackbar('Power BI workspace ID is required', { variant: 'error' });

            return;
        }

        if (!reportData.versions[1]?.powerBIReportId) {
            enqueueSnackbar('Power BI report ID is required', { variant: 'error' });

            return;
        }

        if (!reportData.defaultCategory) {
            enqueueSnackbar('Category is required', { variant: 'error' });

            return;
        }

        if (!reportData.permission.type) {
            enqueueSnackbar('Permission type is required', { variant: 'error' });

            return;
        }

        await createReport(reportData, {
            onSuccess: (data) => {
                console.info('Report created successfully:', data);
                enqueueSnackbar('Report created', { variant: 'success' });
                handleClose();
            },
            onError: (error) => {
                console.error('Error creating report:', error);
                enqueueSnackbar('Could not create report', { variant: 'error' });
            }
        });
    };

    return (
        <React.Fragment>
            <Button variant='contained' color='primary' onClick={handleOpen}>
                Create report
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6' color='primary'>
                            Create Report
                        </Typography>
                        <IconButton
                            onClick={() => {
                                setOpen(false);
                            }}
                        >
                            <Clear />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Accordion fullWidth defaultExpanded>
                                <AccordionSummary expandIcon={<ExpandMore />}>Report name</AccordionSummary>

                                <AccordionDetails>
                                    <Box sx={{ width: '100%' }}>
                                        {Object.entries(LANGUAGES).map((lang) => (
                                            <Box key={lang[0]} sx={{ mb: 2 }}>
                                                <TextField
                                                    name={lang[0]}
                                                    fullWidth
                                                    value={reportData.name[lang[0]]}
                                                    onChange={(e) =>
                                                        setReportData((prev) => ({
                                                            ...prev,
                                                            name: { ...prev.name, [lang[0]]: e.target.value }
                                                        }))
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                        style: {
                                                            color:
                                                                lang[0] === i18n.language
                                                                    ? theme.palette.secondary.main
                                                                    : 'inherit',
                                                            fontWeight: lang[0] === i18n.language ? 'bold' : 'normal'
                                                        }
                                                    }}
                                                    label={lang[1]}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Power BI workspace ID'
                                name='powerBIWprkspaceId'
                                value={reportData.versions[1].powerBIWorkspaceId}
                                onChange={(e) =>
                                    setReportData((prev) => ({
                                        ...prev,
                                        versions: {
                                            ...prev.versions,
                                            1: { ...prev.versions[1], powerBIWorkspaceId: e.target.value }
                                        }
                                    }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Power BI report ID'
                                name='powerBIReportId'
                                value={reportData.versions[1].powerBIReportId}
                                onChange={(e) =>
                                    setReportData((prev) => ({
                                        ...prev,
                                        versions: {
                                            ...prev.versions,
                                            1: { ...prev.versions[1], powerBIReportId: e.target.value }
                                        }
                                    }))
                                }
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label='Internal note'
                                name='internalNote'
                                value={reportData.internalNote}
                                onChange={(e) => setReportData((prev) => ({ ...prev, internalNote: e.target.value }))}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={Object.values(REPORT_CATEGORIES)}
                                value={reportData.defaultCategory}
                                onChange={(_, newValue) => {
                                    setReportData((prev) => ({ ...prev, defaultCategory: newValue }));
                                }}
                                renderInput={(params) => <TextField {...params} label='Category' />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Autocomplete
                                options={Object.values(REPORT_DATASOURCES)}
                                disableCloseOnSelect
                                multiple
                                getOptionLabel={(option) => capitalizeFirstLetter(option)}
                                onChange={(_, newValue) => {
                                    setReportData((prev) => ({ ...prev, dataSources: newValue }));
                                }}
                                renderInput={(params) => <TextField {...params} label='Datasources' />}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel component='legend'>Permission Type</FormLabel>
                            <RadioGroup
                                row
                                aria-label='permission-type'
                                name='permissionType'
                                value={reportData.permission.type}
                                onChange={(e) =>
                                    setReportData((prev) => ({
                                        ...prev,
                                        permission: { ...prev.permission, type: e.target.value }
                                    }))
                                }
                            >
                                <FormControlLabel value='global' control={<Radio color='primary' />} label='Global' />
                                <FormControlLabel
                                    value='selective'
                                    control={<Radio color='primary' />}
                                    label='Selective'
                                />
                            </RadioGroup>
                        </Grid>

                        <Grid item xs={12}>
                            <FormLabel>
                                {reportData.permission.type === 'global'
                                    ? 'Select units to exclude'
                                    : 'Select units that should have access'}
                            </FormLabel>
                            <UnitSelector
                                setSelectedUnits={(units) =>
                                    setReportData((prev) => ({
                                        ...prev,
                                        permission: {
                                            ...prev.permission,
                                            ...(prev.permission.type === 'global'
                                                ? { excludedUnits: units }
                                                : { units })
                                        }
                                    }))
                                }
                                selectedUnits={
                                    reportData.permission.type === 'global'
                                        ? reportData.permission.excludedUnits
                                        : reportData.permission.units
                                }
                                units={units}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color='primary' variant='contained'>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

CreateReport.propTypes = {
    units: PropTypes.any
};

export default CreateReport;
