import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';
import { Container, LinearProgress, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Menu as MenuIcon } from '@material-ui/icons';
import { makeStyles, useTheme } from '@material-ui/styles';

import { AuthContext } from 'context/AuthContext';
import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { ME } from 'graphql/queries/user';

import { CookieAccept, Sidebar, Topbar } from './partials';
import { drawerWidth } from './partials/Topbar/Topbar';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingTop: 56
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 30
        },
        paddingLeft: (props) => (props.isDesktop ? theme.spacing(7) : 0)
    },
    shiftContent: {
        marginLeft: (props) => (props.isDesktop ? drawerWidth - 70 : 0),
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    content: {
        height: '100%',
        minHeight: 'calc(100% - 100px)',
        padding: (props) => (props.isReportPage ? 0 : theme.spacing(4)),
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: (props) => (props.isReportPage ? 0 : theme.spacing(8)),
        [theme.breakpoints.down('sm')]: {
            paddingTop: (props) => (props.isReportPage ? 0 : theme.spacing(8)),
            paddingBottom: (props) => (props.isReportPage ? theme.spacing(2) : 0),
            paddingRight: (props) => (props.isReportPage ? 0 : theme.spacing(4)),
            paddingLeft: (props) => (props.isReportPage ? 0 : theme.spacing(4))
        }
    },
    mobileMenu: {
        align: 'left',
        paddingTop: 26
    },
    menuButton: {
        background: theme.palette.primary.main,
        borderRadius: 'unset',
        width: 56,
        height: 36,
        marginTop: 26,
        color: 'white',
        position: 'fixed',
        zIndex: theme.zIndex.drawer
    }
}));

const Main = ({ children }) => {
    const { data } = useQuery(ME);
    const history = useHistory();
    const { setAuthenticated, setUser } = useContext(AuthContext);
    if (data && data.me === null) {
        history.push('/sign-in');
    }

    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.between('sm', 'xl'), { defaultMatches: true });
    const classes = useStyles({
        isDesktop,
        isReportPage:
            children.props.path === '/powerbi/report/:reportId' || children.props.path === '/report/:reportIndex'
    });
    const [openSidebar, setOpenSidebar] = useState(false);

    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const handleSidebarOpen = () => {
        setOpenSidebar((prev) => !prev);
    };

    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };

    const handleLogout = () => {
        setAuthenticated(null);
        setUser(null);
        history.push('/sign-in');
    };

    useEffect(() => {
        if (data?.me) {
            const { firstName, lastName, email } = data.me;

            setUser({ firstName, lastName, email });
        }
    }, [data, setUser]);

    return (
        <>
            {data && data.me ? (
                <div className={`${classes.root} ${openSidebar && classes.shiftContent}`}>
                    <Topbar
                        units={data.me.units}
                        activeUnit={activeUnit}
                        firstName={data.me.firstName}
                        lastName={data.me.lastName}
                        handleLogout={handleLogout}
                        sideBarOpen={openSidebar}
                        onSidebarOpen={handleSidebarOpen}
                    />
                    {!isDesktop && (
                        <IconButton onClick={handleSidebarOpen} className={classes.menuButton}>
                            <MenuIcon />
                        </IconButton>
                    )}

                    <Sidebar
                        onClose={handleSidebarClose}
                        open={openSidebar}
                        handleSidebarOpen={handleSidebarOpen}
                        variant={isDesktop ? 'permanent' : 'temporary'}
                    />
                    <main className={classes.content}>
                        <Container style={{ maxWidth: 1536 }} /*  maxWidth='xl' */>
                            {(activeUnit && children) || <LinearProgress />}
                        </Container>
                    </main>
                    <CookieAccept />
                </div>
            ) : (
                <LinearProgress />
            )}
        </>
    );
};

Main.propTypes = {
    children: PropTypes.node
};

export default Main;
