import React, { useContext, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import { useLazyQuery } from '@apollo/client';
import { Box, Divider, MenuItem, Select, Typography } from '@material-ui/core';

import 'i18n';

import { Context as activeUnitContext } from 'context/units/activeUnitContext';
import { GET_POWER_BI_REPORT_CONFIG_FOR_UNIT } from 'graphql/queries/unit';

import './report.css';

const DEFAULT_REPORT_CONFIG = {
    type: 'report',
    embedUrl: undefined,
    accessToken: undefined,
    tokenType: models.TokenType.Embed,
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            }
        },
        navContentPaneEnabled: false,
        persistentFiltersEnabled: true,
        background: models.BackgroundType.Transparent
    }
};

export const PowerBIReportContent = ({ versionKeys, report, setIsLoading, setLoadingText }) => {
    const [currentVersionKey, setCurrentVersionKey] = useState(() => {
        const maxVersionKey = versionKeys.length ? Math.max(...versionKeys) : 0;

        return maxVersionKey;
    });

    const {
        state: { activeUnit }
    } = useContext(activeUnitContext);

    const bookmarksManager = useRef({});
    const reportRef = useRef({});
    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [isSuccess, setIsSuccess] = useState(false);

    const [reportConfig, setReportConfig] = useState(DEFAULT_REPORT_CONFIG);

    const [getUnitPowerBIReportConfig] = useLazyQuery(GET_POWER_BI_REPORT_CONFIG_FOR_UNIT, {
        onError: (error) => {
            console.error(error);
            enqueueSnackbar(t('Report could not be loaded'), { variant: 'error' });
            setIsSuccess(false);
            setIsLoading(false);
        },
        onCompleted: (res) => {
            if (res?.getPowerBIReportConfig && res?.getPowerBIReportConfig?.embeddedToken) {
                const { embeddedToken } = res.getPowerBIReportConfig;
                const { token, embedUrl, filters, isEditable, settings } = embeddedToken;

                let config = {
                    accessToken: token,
                    embedUrl,
                    filters: filters.map(
                        ({ schema, target, operator, values, filterType, requireSingleSelection }) => ({
                            $schema: schema,
                            target,
                            operator,
                            values,
                            filterType,
                            requireSingleSelection
                        })
                    ),
                    settings: {
                        ...settings,
                        navContentPaneEnabled: false
                    }
                };

                if (isMobile) {
                    config = {
                        ...config,
                        settings: { ...config?.settings, layoutType: models.LayoutType.MobilePortrait }
                    };
                }

                if (isEditable) {
                    config = {
                        ...config,
                        permissions: models.Permissions.ReadWrite,
                        viewMode: models.ViewMode.Edit
                    };
                }

                setReportConfig((prev) => ({
                    ...prev,
                    ...config
                }));
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
                setIsLoading(false);
            }
        }
    });

    useEffect(() => {
        if (report && activeUnit) {
            setIsLoading(true);
            setLoadingText('Retrieving and analyzing data...');

            const latestVersion = currentVersionKey ? report.versions[currentVersionKey] : {};

            getUnitPowerBIReportConfig({
                variables: {
                    id: report.id,
                    mangled: false,
                    reportId: latestVersion.powerBIReportId,
                    groupId: latestVersion.powerBIWorkspaceId,
                    unitId: activeUnit.id,
                    locale: i18n?.language
                }
            });
        }
    }, [getUnitPowerBIReportConfig, setIsLoading, setLoadingText, report, activeUnit, i18n, currentVersionKey]);

    return (
        <>
            {versionKeys.length > 1 && (
                <Box sx={{ width: '100%', pt: 1, pb: 1 }}>
                    <Box sx={{ mb: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Typography style={{ marginRight: '8px', fontSize: 12 }}>{t('Version')}</Typography>
                        <Select
                            style={{ fontSize: 12 }}
                            value={currentVersionKey}
                            onChange={(val) => {
                                setCurrentVersionKey(val.target.value);
                            }}
                        >
                            {versionKeys.map((versionKey) => (
                                <MenuItem key={versionKey} value={versionKey}>
                                    {versionKey}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Divider />
                </Box>
            )}

            {isSuccess && (
                <PowerBIEmbed
                    key={
                        report.versions[currentVersionKey].powerBIReportId +
                        report.versions[currentVersionKey].powerBIWorkspaceId
                    }
                    embedConfig={reportConfig}
                    eventHandlers={
                        new Map([
                            [
                                'loaded',
                                function () {
                                    /* eslint-disable-next-line */
                                    console.log('Report has loaded');
                                    setLoadingText('Showing report...');

                                    setTimeout(() => {
                                        setIsLoading(false);
                                    }, 3000);
                                }
                            ],
                            [
                                'rendered',
                                async function () {
                                    /* eslint-disable-next-line */
                                    console.log('Report has rendered');
                                    setLoadingText('Crunching datasets...');
                                }
                            ],
                            [
                                'error',
                                function (event) {
                                    if (event) {
                                        /* eslint-disable-next-line */
                                        console.error(event.detail);
                                    }
                                }
                            ]
                        ])
                    }
                    cssClassName={'report-style-class'}
                    getEmbeddedComponent={(rep) => {
                        if (rep) {
                            reportRef.current = rep;
                            bookmarksManager.current = rep.bookmarksManager;
                        }
                    }}
                />
            )}
        </>
    );
};

PowerBIReportContent.displayName = 'PowerBIReportContent';

PowerBIReportContent.propTypes = {
    versionKeys: PropTypes.array,
    report: PropTypes.object,
    setIsLoading: PropTypes.func,
    setLoadingText: PropTypes.func
};
